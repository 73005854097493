html { 
    background: url('/pipeline.jpg') no-repeat center center fixed; 
    background-blend-mode: saturation;
    backdrop-filter: brightness(10%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    max-width: 90%;
    overflow: hidden;
}

.title {
    font-size: 50px;
    font-family: 'Segoe UI', Tahoma, sans-serif, sans-serif;
    color: rgb(255,255,255);
    max-width: 90%;
}

.linked-demo {
    text-decoration-color: #ffffff;
}

.bin {
    font-family: Inter var, sans-serif;
    display: inline;
    font-size: 25px;
    color: rgb(255,255,255);
    -webkit-text-fill-color: rgb(93, 153, 190);
    -webkit-text-stroke: 1px black;
    padding-left: 3px;
    padding-right: 3px;
    border: black;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.3rem;
    margin-right: 10px;
    vertical-align: 21%;
}

.subtitle {
    font-size: 40px;
    font-family: 'Segoe UI', Tahoma, sans-serif, sans-serif;
    color: rgb(255,255,255);
    margin: auto;
}

.tinytitle {
  font-size: 20px;
  font-family: 'Segoe UI', Tahoma, sans-serif, sans-serif;
  color: rgb(255,255,255);
  margin: auto;
  padding-top: 2%;
}

.mini {
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, sans-serif, sans-serif;
    color: rgb(255,255,255);
    left: 50px;
    bottom:30%;
    position: fixed;
    max-width: 100%;
}

.chevron {
    position: fixed;
    right: 7%;
    top: 35%;
    color: rgb(255,255,255);
	border-style: solid;
	border-width: 0.25em 0.25em 0 0;
	content: '';
	display: inline-block;
	height: 1.80em;
	transform: rotate(-45deg);
    width: 1.80em;
    padding: 5px;
}

.chevron:hover {
    cursor: pointer;
    transition-duration: 1.5s;
    color: #FCC676;
}

.chevron.down{
    margin-top: 9%;
    transform: rotate(135deg);
}

.mover {
    font-size: 21px;
    font-family: 'Segoe UI', Tahoma, sans-serif, sans-serif;
    color: rgb(255,255,255);
    margin-top: 25px;
    transition: color 1.5s;
}

.movers {
    position: fixed;
    margin-top: 50px;
    margin-left: 750px;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.icon {
    height: 30px;
    padding-top: 10px;
    padding-right: 10px;
}

.bigListItem {
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, sans-serif, sans-serif;
    color: rgb(255,255,255);
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    margin: auto;
}

.content {
    position: absolute;
    width: 650px;
    padding-left: 3%;
    max-width: 90%;
}

.listItem {
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, sans-serif, sans-serif;
    color: rgb(255,255,255);
    padding-top: 1%;
    margin: auto;
}

.currmover {
    font-size: 21px;
    font-family: 'Segoe UI', Tahoma, sans-serif, sans-serif;
    color: rgb(252, 198, 118);
    padding-top: 25px;
    cursor: default;
}

.mover:hover {
    font-weight: 530; 
    position: relative;  
    right: -4px; 
    transition-duration: 1.5s;
    color: #FCC676;
}
